import svg4everybody from 'svg4everybody';
import config from '../views/data/config.json';
import en from '../locales/en.json';
import ru from '../locales/ru.json';
import $ from 'jquery';
import Cookies from 'js-cookie';

if (!window.site) window.site = {};

// One time after page loading
window.site.initPage = function () {
  calculateTotals();
};

// Every time after creating new elements. For example: after ajax loading
window.site.initElements = function () {
  svg4everybody();
};

window.addEventListener('load', () => {
  window.site.initPage();
  window.site.initElements();
});

const queryParams = window.location.href.split('?');
const searchParams = new URLSearchParams(queryParams.length > 1 ? queryParams[1] : '');

if (searchParams.has('ref')) {
  Cookies.set('ref', searchParams.get('ref'), {expires: 31, domain: '.honeypot.game'});
}

// 2. This code loads the IFrame Player API code asynchronously.
// const tag = document.createElement('script');
//
// tag.src = "https://www.youtube.com/iframe_api";
// const firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
let player = null;
let playerEn = null;
let currentLang = 'ru';

const onYouTubeIframeAPIReady = () => {
  player = new YT.Player('player', {
    width: 1920,
    height: 1080,
    videoId: 'sEEOZ62qA-s',
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  });

  playerEn = new YT.Player('player-en', {
    width: 1920,
    height: 1080,
    videoId: '8uy7lZ1YoeI',
    events: {
      'onReady': onPlayerEnReady,
      'onStateChange': onPlayerStateChange
    }
  });
}

window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

let isReady = false;
let isReadyEn = false;
// 4. The API will call this function when the video player is ready.
function onPlayerReady() {
  isReady = true;
}
function onPlayerEnReady() {
  isReadyEn = true;
}

// 5. The API calls this function when the player's state changes.
//    The function indicates that when playing a video (state=1),
//    the player should play for six seconds and then stop.
// let done = false;
function onPlayerStateChange(event) {
  // if (event.data == YT.PlayerState.PLAYING && !done) {
  //   setTimeout(stopVideo, 6000);
  //   done = true;
  // }
}

function stopVideo() {
  if (isReady && isReadyEn) {
    player.stopVideo();
    playerEn.stopVideo();
  }
}

function playVideo() {
  if (isReady && isReadyEn) {
    if (currentLang === 'ru') {
      player.playVideo();
    } else {
      playerEn.playVideo();
    }
    document.getElementById('video-bg').classList.add('d-none');
  } else {
    setTimeout(playVideo, 1000);
  }
}

document.getElementById('video-bg').addEventListener('click', () => {
  playVideo();
});

const round = (num, decimals) => {
  const factor = 10 ** decimals;
  return Math.round(num * factor) / factor;
};

const repairPrecision = (num) => round(num, 10);

const roundUp = (num, decimals) => {
  num = repairPrecision(num);
  const factor = 10 ** decimals;
  const magnified = repairPrecision(num * factor);
  return Math.ceil(magnified) / factor;
};

function numberWithCommas(x) {
  const value = roundUp(x, 2);
  const parts = value.toString().split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;

}

let count = [0,0,0,0,0,0,0];
const totalCost = [0,0,0,0,0,0,0];
const totalProfit = [0,0,0,0,0,0,0];
const totalSlots = [0,0,0,0,0,0,0];
const multipliers = [0,0,0,0,0,0,0];
let nftCost = [0,0,0,0,0,0,0];
const nftCostChecked = [0,0,0,0,0,0,0];
let setBonus = 1;

const calculateTotals = () => {
  const reduce = multipliers.reduce((v, n) => v + n, 0);
  const allBees = count.indexOf(0) === -1;
  setBonus = allBees && roundUp(reduce, 2) * 10 / multipliers.length === multipliers[0] * 10 ? 2 : 1;

  count.forEach((v, i) => {
    totalCost[i] = v * config.bees[i].price;
    totalProfit[i] = v * config.bees[i].baseDailyProfit * 2 * ((multipliers[i] * setBonus + 1) * 10) / 10;
    totalSlots[i] = v * config.bees[i].slots;
    nftCostChecked[i] = v ? nftCost[i] : 0;
  });
  setValues();
};

document.getElementById('js-reset').addEventListener('click', (e) => {
  e.preventDefault();
  count = [0,0,0,0,0,0,0];
  nftCost = [0,0,0,0,0,0,0];
  calculateTotals();
  setNftLabels();
});

document.addEventListener("unload", calculateTotals);

const setNftLabels = () => {
  const source = currentLang === 'ru' ? ru : en;
  multipliers.forEach((value, i) => {
    document.getElementById('nft-list-' + (i + 1)).innerText = value !== 0 ? `+${parseInt((1 + multipliers[i]) * 100 - 100)}%` : source.no;
  });
};

const setValues = () => {
  count.forEach((v, i) => {
    document.getElementById('input-' + (i + 1)).value = v;
    document.getElementById('bee-cost-' + (i + 1)).innerText = numberWithCommas(totalCost[i]);
    document.getElementById('bee-income-' + (i + 1)).innerText = numberWithCommas(totalProfit[i]);
    document.getElementById('bee-slots-' + (i + 1)).innerText = numberWithCommas(totalSlots[i]);
    document.getElementById('bee-nft-cost-' + (i + 1)).innerText = numberWithCommas(nftCostChecked[i]);
    document.getElementById('nft-cost-' + (i + 1)).classList.toggle('d-none', nftCostChecked[i] === 0);
  });

  const totalDailyIncome = totalProfit.reduce((v, n) => v + n, 0);
  const totalDailyIncomeUsd = totalDailyIncome * config.swapRate / 10000;
  const slotsCount = totalSlots.reduce((v, n) => v + n, 0);
  const totalNft = nftCostChecked.reduce((v, n) => v + n, 0);
  const priceForSlots = slotsCount <= 10 ? 0 : roundUp((slotsCount - 10) / 10, 0) * config.slotPrice * 10;
  const totalPrice = totalCost.reduce((v, n) => v + n, 0) + priceForSlots + totalNft;

  document.getElementById('bee-total-slots').innerText = numberWithCommas(slotsCount);
  document.getElementById('bee-total-cost').innerText = numberWithCommas(totalPrice);
  document.getElementById('bee-total-income').innerText = numberWithCommas(totalDailyIncome);

  document.getElementById('bee-total-cost-usd').innerText = numberWithCommas(roundUp((totalPrice) * config.swapRate / 10000, 2));
  document.getElementById('bee-total-income-usd').innerText = numberWithCommas(roundUp(totalDailyIncomeUsd, 2));

  document.getElementById('daily-income').innerText = numberWithCommas(totalDailyIncome);
  document.getElementById('daily-income-usd').innerText = numberWithCommas(roundUp(totalDailyIncomeUsd, 2));

  document.getElementById('weekly-income').innerText = numberWithCommas(roundUp(totalDailyIncome * 7, 2));
  document.getElementById('weekly-income-usd').innerText = numberWithCommas(roundUp(totalDailyIncomeUsd * 7, 2));

  document.getElementById('monthly-income').innerText = numberWithCommas(roundUp(totalDailyIncome * 30, 2));
  document.getElementById('monthly-income-usd').innerText = numberWithCommas(roundUp(totalDailyIncomeUsd * 30, 2));

  document.getElementById('yearly-income').innerText = numberWithCommas(roundUp(totalDailyIncome * 365, 2));
  document.getElementById('yearly-income-usd').innerText = numberWithCommas(roundUp(totalDailyIncomeUsd * 365, 2));
}

const changeCount = (id, value) => {
  const prev = count[id-1];
  count[id - 1] = value === -1 && prev === 0 ? 0 : prev + value;
  calculateTotals();
};

const addButtons = document.querySelectorAll('.js-add-bee');
const subButtons = document.querySelectorAll('.js-sub-bee');
const nftMultiplier = document.querySelectorAll('.dropdown-item');

addButtons.forEach((el) => {
  el.addEventListener('click', (event) => {
    changeCount(event.target.dataset.id, 1);
  });
});

subButtons.forEach((el) => {
  el.addEventListener('click', (event) => {
    changeCount(event.target.dataset.id, -1);
  });
});

nftMultiplier.forEach((el) => {
  el.addEventListener('click', (event) => {
    event.preventDefault();
    const bee = event.target.dataset.bee;
    const value = parseInt(event.target.dataset.value);
    const nftSet = config.sets.find(s => s.bonusPercents === value);

    multipliers[bee - 1] = value ? (1 / 100 * value) : 0;
    nftCost[bee - 1] = nftSet ? nftSet.items[bee - 1].price : 0;

    calculateTotals();
    setNftLabels();
  });
});

const switchLang = document.querySelectorAll('.js-switch-lang');
const langText = document.querySelectorAll('[data-lang]');

const translate = (lang) => {
  localStorage.setItem('lang', lang);
  const source = lang === 'ru' ? ru : en;
  langText.forEach((el) => {
    el.innerText = source[el.dataset.lang];
  });

  window.alertText = source['alert-text'];

  currentLang = lang;
  stopVideo();
  document.body.classList.toggle('lang-ru', lang === 'ru');
  document.body.classList.toggle('lang-en', lang === 'en');
  setNftLabels();
};

switchLang.forEach((el) => {
  el.addEventListener('click', (event) => {
    event.preventDefault();
    translate(event.target.dataset.language);
  });
});

window.addEventListener('load', () => {
  const lang = localStorage.getItem('lang');

  if (lang) {
    translate(lang);
  }
});

$(document).ready(() => {
  $(document).on('click', '.nav-link, footer ul a, .read-more', function(e) {
    e.preventDefault();
    e.stopPropagation();

    const href = $(this).attr('href');

    $('html, body').stop(true).animate({
      scrollTop: $(href).offset().top - (href === '#about' ? 0 : 100)
    }, 500);
  });

  $(document).on('click', '.js-switch-lang', function(e) {
    setTimeout(() => {
      $('.slick').slick('refresh');
    }, 50);
  });

  $(".slick").slick({
    // normal options...
    slidesToShow: 6,
    infinite: true,
    dots: false,
    nextArrow: '.js-next',
    prevArrow: '.js-prev',

    // the magic
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      }
    ]
  });
});
